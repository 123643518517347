body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #667fa4, #ccd4e1);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  height: auto;
}

body > div {
  height: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: #002967;
  text-decoration: none;
  font-weight: 600;
  text-decoration: none;
  font-size: 16px;
}

a:hover {
  text-decoration: underline;
}
