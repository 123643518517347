.attachmentButton {
	margin-bottom: 2rem;
}

.modalContainer {
	display: flex;
	min-width: 30vw;
	align-items: stretch;
	flex-flow: column nowrap;
	padding: 1rem;
}

.header {
	display: flex;
	justify-content: space-between;
	border-top: 4px solid var(--palette-primary-main);
	color: var(--palette-grey-9);
	flex: 1 1 auto;
	font-weight: 600;
}

.heading {
	padding: 12px 12px 14px 24px;
	font-size: 2em;
	text-align: center;
	width: 100%;
}

.close {
	margin-top: 4px;
	margin-right: 2px;
	margin-left: auto;
	color: var(--palette-primary-main);
	&:hover {
		color: var(--palette-grey-1);
	}
}

.modalContent {
	padding: 1rem 0 2.5rem;
	text-align: center;
	font-size: 1.3em;
}

.modalFooter {
	display: flex;
	justify-content: center;
}
