@import '../../../common/variables';
@import '../../../common/mixins';

.content {
	@include content;

	> div {
		width: 95%;

		h2 {
			@include header2;
		}
		p {
			@include paragraph;
		}
	}
}
