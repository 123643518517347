@import '../../common/variables';

.button {
	padding-top: 0.625em;
	padding-bottom: 0.625em;
	font-size: 0.9375rem;
	height: 40px;

	&.primary {
		background-color: $buttonBgBlue;
		color: $white;
	}

	&.secondary {
		border: 0;
	}

	span {
		font-weight: 400;
	}
}
