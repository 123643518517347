.processBar {
	width: 100%;
	background-color: #f2f4f7;
	height: 29px;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.processBarStep {
	width: 25%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #cacbd1;

	&.active {
		background-color: #1f2a35;
		color: white;
	}
}
