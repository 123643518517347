@import '../../../common/variables';
@import '../../../common/mixins';

.content {
	@include content;

	> div {
		width: 100%;

		h1 {
			@include header1;
		}

		h2 {
			@include header2;
		}

		.description {
			@include paragraph;

			p {
				margin-bottom: 1em;
			}
		}
	}
}

.dialog {
	@include dialog;
}
