@import '../../../common/mixins';
@import '../../../common/variables';

.content {
	@include content;

	> div {
		width: 95%;
	}
}

.buttons {
	@include buttons;
}
