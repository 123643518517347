@import '../common/variables';

.pageLayout {
	width: 100%;
	height: 100%;
	position: relative;
}

.contentBox {
	width: 100%;
	max-width: 800px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	margin: 0 auto;
}

.pageContent {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
	background: $white;

	aside {
		width: 30%;

		img {
			max-width: 100%;
		}
	}
}
