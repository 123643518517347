@import './variables';

@mixin content {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 1rem;
	background: $white;
	margin-bottom: 50px;

	&.isSubPage {
		flex-wrap: nowrap;
	}

	h1 {
		@include header1;
	}

	h2 {
		@include header2;
	}

	h3 {
		@include header3;
	}

	h4 {
		@include header4;
	}

	h5 {
		@include header5;
	}

	p {
		@include paragraph;
	}
}

@mixin buttons {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;

	button[class*='is-disabled'] {
		background-color: rgb(243, 242, 241);

		span {
			color: rgb(161, 159, 157);
		}
	}
}

@mixin header1 {
	color: $headerGray;
	font-weight: 400;
	line-height: 1.25;
	margin-bottom: 0.5em;
	font-size: 28px;
	font-family: InterstateRegular, Calibri, sans-serif;
}

@mixin header2 {
	color: $headerGray;
	font-weight: 400;
	line-height: 1.25;
	margin-bottom: 0.5em;
	font-size: 20px;
}

@mixin header3 {
	color: $headerGray;
	font-weight: 400;
	line-height: 1.25;
	margin-bottom: 0.5em;
	font-size: 18px;
	width: 100%;
}

@mixin header4 {
	text-align: center;
	color: $headerGray;
	font-size: 16px;
	line-height: 1.25;
	margin-bottom: 0.5em;
}

@mixin header5 {
	color: $headerGray;
	font-size: 16px;
	line-height: 1.25;
	margin-bottom: 0.5em;
	font-weight: 400;
}

@mixin paragraph {
	color: $textGray;
	margin-bottom: 2em;
	font-size: 16px;
	width: 100%;

	ol {
		padding: 0 20px;
	}
}

@mixin textField {
	width: 100%;

	[class*='ms-TextField-fieldGroup'] {
		border: none;
		border-radius: 0;

		&::after {
			content: none;
		}
	}

	[class*='ms-TextField-errorMessage'] {
		@include error;
	}

	input,
	textarea {
		background-color: #f2f4f7;
		padding: 0.5rem 1rem;
		width: 100%;
		font-size: 15px;
		color: rgb(73, 85, 100);
		border: 0;

		&:focus-visible {
			outline: 2px dashed #f09700 !important;
		}
	}

	&[class*='is-disabled'] {
		label {
			color: #323130;

			&::after {
				content: none;
			}
		}

		input,
		textarea {
			background-color: transparent;
		}
	}
}

@mixin choiceField {
	[class*='ms-ChoiceFieldGroup-flexContainer'] {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		flex-direction: column;

		> div {
			margin-top: 0;

			&:last-of-type {
				margin-bottom: 1rem;
			}
		}

		input {
			width: auto;
		}
	}

	&.isColumn {
		[class*='ms-ChoiceFieldGroup-flexContainer'] {
			flex-direction: column;
		}
	}
}

@mixin dropdownField {
	width: auto;

	> div {
		&:focus::after {
			border: 2px dashed #f09700;
		}
	}

	[class*='ms-Dropdown-title'] {
		border: 0;
		background-color: #f2f4f7;

		&:focus-visible {
			outline: 2px dashed #f09700 !important;
		}
	}

	&.isDisabled {
		label {
			color: #323130;

			&::after {
				content: none;
			}
		}

		i {
			visibility: hidden;
		}

		[class*='ms-Dropdown-title'] {
			background-color: transparent;
			color: #495564;

			> div {
				&:focus {
					outline: none;
				}
			}
		}
	}
}

@mixin error {
	color: #f09700;
	font-size: 12px;
}

@mixin dialog {
	> div[class*='ms-Dialog-main'] {
		min-width: 25%;
	}

	.dialogInner {
		width: 100%;

		h2 {
			text-align: center;
			font-weight: 400;
		}
	}

	.dialogButtons {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 35px;
	}
}

@mixin attachmentsList {
	width: 100%;
	margin-bottom: 2em;
	color: #495564;

	.attachmentRow {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ddd;
		padding: 5px 2px 5px 5px;
		color: #495564;
	}

	.attachmentRow i {
		color: #495564;
		font-size: 1.3em;
	}
}
