.header {
	width: 100%;
	height: 4.5rem;
	margin: 20px 0;
}

.wrapper {
	margin: 0 auto;
	height: 100%;
	padding: 0 30px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;

	nav {
		height: 100%;
		display: flex;
		align-items: center;

		button {
			opacity: 0.6;
			transition: opacity 0.3s;
			border: 0;

			&:hover {
				opacity: 1;
				background-color: transparent;
			}
			span {
				align-items: flex-start;
			}

			svg {
				width: 33px;
				height: 31px;
			}
		}
	}
}

.logo {
	font-size: 1.0625rem;
	margin: 0;
	width: 14rem;

	a {
		width: 100%;
		display: block;
	}

	img {
		width: 100%;
		height: auto;
		display: block;
	}
}
