@import '../../../common/mixins';

.infoPageContent {
	width: 100%;
	padding: 1rem;

	h1 {
		@include header1;
	}

	p {
		@include paragraph;
	}
}

.buttons {
	@include buttons;
}

[id*='onetrust-consent-sdk'] {
	[class*='otPcCenter']:first-of-type {
		display: none;
	}
}
