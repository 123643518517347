@import '../../common/variables';

footer {
	height: 29px;
	width: 100%;
	background-color: #1f2a35;
	flex-shrink: 0;
	display: flex;
	align-items: center;

	.footerWrapper {
		max-width: 1170px;
		margin: 0 auto;
	}

	ul {
		list-style: none;
		display: flex;
		justify-content: center;

		li {
			padding: 0 1rem;
			color: $white;
		}
	}

	a {
		color: white;
		text-decoration: none;
		font-weight: 400;
	}
}
