@import '../../common/mixins';
@import '../../common/variables';

.fields {
	width: 100%;
	margin-bottom: 2rem;
}

.textInput {
	@include textField;
}

.choiceInputs {
	@include choiceField;
}

.dropdownInput {
	@include dropdownField;
}

.buttons {
	@include buttons;
}

.linebreak {
	width: 100%;
	height: 1px;
	background-color: #ddd;
	margin-bottom: 2rem;
}
