@import '../../common/variables';

.captcha {
	display: flex;
	flex-wrap: wrap;
	margin: 1rem 0;

	> div {
		display: flex;
		width: 100%;

		canvas {
			margin-right: 1rem;
		}

		> div {
			display: flex;
			align-items: center;

			a {
				font-size: 0.9rem;
				font-weight: bold;
				color: $buttonBgBlue !important;
			}
		}
	}

	.captchaField {
		width: 75%;

		div {
			width: 100%;
			height: 40px;
		}

		[class*='ms-TextField-fieldGroup'] {
			border: none;
			border-radius: 0;

			&::after {
				content: none;
			}
		}

		input {
			background-color: #f2f4f7;
			padding: 0.5rem 1rem;
			width: 100%;
			font-size: 17px;
			color: rgb(73, 85, 100);

			&:focus-visible {
				outline: 2px dashed #f09700 !important;
			}
		}
	}
}
