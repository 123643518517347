@import '../../../common/mixins';
@import '../../../common/variables';

.content {
	@include content;
}

.requestId {
	text-align: center;

	span {
		display: inline-block;
		padding: 1rem 0;
		font-size: 1.5rem;
		font-weight: bold;
	}
}

.buttons {
	width: 100%;
	display: flex;
	gap: 1.5rem;
}

.isLoading {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	> div {
		width: 100%;
	}
}
