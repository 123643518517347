.notFound {
	width: 100%;
	color: #000;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 6rem 0;

	> div {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.notFoundIcon {
		font-size: 6rem;
	}

	h2 {
		font-size: 2rem;
	}

	.homeButton {
		width: 100%;
	}
}
