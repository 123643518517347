@import '../../../common/mixins';
@import '../../../common/variables';

.content {
	@include content;
}

.sections {
	width: 100%;
}

.buttons {
	@include buttons;
}

.section {
	width: 100%;
}
